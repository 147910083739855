var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "terminalManagement", staticClass: "terminal-management" },
    [
      _c(
        "div",
        { ref: "functionButtons", staticClass: "function-buttons" },
        [
          _vm.$store.state.menu.nowMenuList.indexOf("新增") >= 0
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.onAddTerminal }
                },
                [_vm._v("新增")]
              )
            : _vm._e(),
          _vm.$store.state.menu.nowMenuList.indexOf("导入") >= 0
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.importExcel }
                },
                [_vm._v("导入")]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { ref: "form", staticClass: "form-area" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: {
                inline: true,
                model: _vm.form,
                "label-position": "left",
                "label-width": "72px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "终端号：", prop: "deviceNo" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入终端号" },
                    model: {
                      value: _vm.form.deviceNo,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "deviceNo", $$v)
                      },
                      expression: "form.deviceNo"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "车牌号：", prop: "cph" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入车牌号" },
                    model: {
                      value: _vm.form.cph,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "cph", $$v)
                      },
                      expression: "form.cph"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "所属企业：", prop: "company" } },
                [
                  _c("companySelect", {
                    attrs: {
                      clearable: true,
                      companyTree: _vm.companyList,
                      valueName: _vm.valueName,
                      value: _vm.value
                    },
                    on: { getValue: _vm.getGroupId }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "设备型号：", prop: "deviceModelId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.form.deviceModelId,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.form,
                            "deviceModelId",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.deviceModelId"
                      }
                    },
                    _vm._l(_vm.deviceModelIdList, function(item, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: { label: item.deviceName, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "设备状态：", prop: "status" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.form.status,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.form,
                            "status",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.status"
                      }
                    },
                    _vm._l(_vm.statusList, function(item, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: { label: item.deviceName, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.onSearchClick }
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        disabled: _vm.tableData.length === 0,
                        loading: _vm.downLoadStatus
                      },
                      on: { click: _vm.exportData }
                    },
                    [_vm._v("导出")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          attrs: { data: _vm.tableData, stripe: "", height: _vm.tableHeight },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", { attrs: { type: "index", label: "序号" } }),
          _c("el-table-column", {
            attrs: {
              prop: "deviceNo",
              label: " 终端号",
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "serialNo",
              label: "自编号",
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "companyName",
              label: "所属企业",
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", { attrs: { prop: "cph", label: "车牌号" } }),
          _c("el-table-column", {
            attrs: { prop: "deviceMac", label: "MAC地址" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "deviceName",
              label: "设备型号",
              width: "140",
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "createTime",
              label: "入库时间",
              width: "180",
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.$store.state.menu.nowMenuList.indexOf("编辑") >= 0
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              size: "small",
                              sort: "primary"
                            },
                            on: {
                              click: function($event) {
                                return _vm.onUpdateTerminal(scope.row)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    scope.row.status === 0
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              size: "small",
                              sort: "danger"
                            },
                            on: {
                              click: function($event) {
                                return _vm.changeTerminalStatus(scope.row, 1)
                              }
                            }
                          },
                          [_vm._v("禁用")]
                        )
                      : _vm._e(),
                    scope.row.status === 1
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { color: "#67c23a" },
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.changeTerminalStatus(scope.row, 0)
                              }
                            }
                          },
                          [_vm._v("启用")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { ref: "pagination", staticClass: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              "page-size": _vm.form.pageSize,
              "current-page": _vm.form.currentPage
            },
            on: {
              "current-change": _vm.onCurrentChange,
              "size-change": _vm.onSizeChange
            }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: _vm.title, visible: _vm.dialogVisible, width: "30%" },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.onDialogClose
          }
        },
        [
          _c("div", [
            _c(
              "div",
              { staticClass: "body" },
              [
                _c(
                  "el-form",
                  {
                    ref: "addForm",
                    staticClass: "demo-form dialog-form",
                    attrs: {
                      model: _vm.addForm,
                      rules: _vm.rules,
                      "label-width": "110px",
                      "label-position": "left"
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "自编号：", prop: "serialNo" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入设备自编号" },
                          model: {
                            value: _vm.addForm.serialNo,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.addForm,
                                "serialNo",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "addForm.serialNo"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "终端号：", prop: "deviceNo" } },
                      [
                        _c("el-input", {
                          attrs: {
                            disabled: _vm.isDeviceNoEdit,
                            placeholder: "请输入终端号"
                          },
                          model: {
                            value: _vm.addForm.deviceNo,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.addForm,
                                "deviceNo",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "addForm.deviceNo"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "设备型号：", prop: "deviceModelId" } },
                      [
                        _c(
                          "el-select",
                          {
                            model: {
                              value: _vm.addForm.deviceModelId,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.addForm,
                                  "deviceModelId",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "addForm.deviceModelId"
                            }
                          },
                          _vm._l(_vm.deviceModelIdList, function(item, key) {
                            return _c("el-option", {
                              key: key,
                              attrs: { label: item.deviceName, value: item.id }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "所属企业：", prop: "companyId" } },
                      [
                        _c("companySelect", {
                          ref: "companySelect",
                          attrs: {
                            clearable: true,
                            companyTree: _vm.companyList,
                            valueName: _vm.valueNameAdd,
                            value: _vm.valueAdd
                          },
                          on: { getValue: _vm.getAddGroupId }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "MAC地址：", prop: "deviceMac" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入MAC地址" },
                          model: {
                            value: _vm.addForm.deviceMac,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.addForm,
                                "deviceMac",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "addForm.deviceMac"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "磁盘序号：", prop: "disk" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入磁盘序号" },
                          model: {
                            value: _vm.addForm.disk,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.addForm,
                                "disk",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "addForm.disk"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "SIM卡号：", prop: "sim" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入SIM卡号" },
                          model: {
                            value: _vm.addForm.sim,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.addForm,
                                "sim",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "addForm.sim"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "生产日期：", prop: "produceTime" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            placeholder: "选择日期",
                            type: "date",
                            "picker-options": _vm.pickerDateOptions
                          },
                          model: {
                            value: _vm.addForm.produceTime,
                            callback: function($$v) {
                              _vm.$set(_vm.addForm, "produceTime", $$v)
                            },
                            expression: "addForm.produceTime"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "default", size: "small" },
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    loading: _vm.editLoading
                  },
                  on: { click: _vm.onSave }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "导入", visible: _vm.downVisible, width: "30%" },
          on: {
            "update:visible": function($event) {
              _vm.downVisible = $event
            }
          }
        },
        [
          _c("uploadFile", {
            staticClass: "import",
            attrs: { url: "base/device/importDevice", type: 2 },
            on: { uploadSuccess: _vm.uploadSuccess }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }